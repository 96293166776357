import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Ui from './ui/Ui';
import Model from './model/Model';

import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {Button, Grid, Paper} from '@material-ui/core';
import {hostnameOptions} from './hostnameOptions';
import {generateDynamicManifest} from './ui/utils/Utils';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#1DAAE1',
            main: '#2A5FA6',
            contrastText: '#ffffff'
        }
    },
    typography: {
        fontFamily: 'Arial, monospace'
    }
});

/**
 * Renders the main application.
 *
 * @param {boolean} isPartnerApp - If true start the app in application mode
 */
function renderApplicationUI(
    isPartnerApp: boolean,
    patientType: string,
    commServerUrl: string
): void {
    // Instantiate the models
    const model = new Model(isPartnerApp, commServerUrl);

    // Render the Ui
    ReactDOM.render(
        <ThemeProvider theme={theme}>
            <Ui
                model={model}
                isPartnerApp={isPartnerApp}
                settings={model.settings.getChild('ui')}
                patientType={patientType}
            />
        </ThemeProvider>,
        document.getElementById('root')
    );
}

/**
 * Renders the selection screen that decides whether partner or patient app should be started.
 *
 * @returns {Promise<boolean>} - True for partner app, false for patient app
 */
function renderPatientPartnerSelector(): Promise<boolean> {
    return new Promise(resolve => {
        ReactDOM.render(
            <ThemeProvider theme={theme}>
                <div className="product-type-wrapper">
                    <Grid className="pulse">
                        <Grid className="product-item" item xs={12}>
                            <Paper className="product-text">
                                The application is in development mode. In order to change the
                                product type, you must clear the storage first
                                <div className="product-type-buttons-wrapper">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="product-type-button"
                                        onClick={() => {
                                            resolve(true);
                                        }}
                                    >
                                        Partner
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="product-type-button"
                                        onClick={() => {
                                            resolve(false);
                                        }}
                                    >
                                        Patient
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </ThemeProvider>,
            document.getElementById('root')
        );
    });
}

/**
 * Starts the whole application.
 *
 * In development we let the user decide whether he wants to start the partner or patient app.
 * In production it is determined based on the url.
 *
 * @returns {Promise<void>}
 */
async function startApplication(): Promise<void> {
    // const commServerUrl = 'wss://comm.dev.refinio.one';
    const commServerUrl = 'wss://uke-comm.freeda.one';
    // const commServerUrl = 'ws://localhost:8000';
    // const commServerUrl = 'wss://comm.freeda.refinio.one';

    // Check whether we are in development or not.
    const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    let isPartnerApp: boolean;
    let patientType: string;

    // Determine whether to start Partner mode or Patient mode
    if (isDevelopment) {
        const isPartnerAppFromStorage: string | null = localStorage.getItem('isPartnerApp');

        // If we don't have a value in storage, then display the selection page
        // If we have a value in storage, then just use this value
        if (isPartnerAppFromStorage === null) {
            isPartnerApp = await renderPatientPartnerSelector();
            localStorage.setItem('isPartnerApp', String(isPartnerApp));
        } else {
            isPartnerApp = isPartnerAppFromStorage === 'true';
        }

        // Let's use the cancer type as default in dev mode
        if (isPartnerApp) {
            patientType = 'cancer partner';
        } else {
            patientType = 'cancer';
        }
    } else {
        patientType = hostnameOptions[window.location.hostname].patientType;
        isPartnerApp = hostnameOptions[window.location.hostname].patientType.includes('partner');
    }

    // Render the main application after the isPartnerApp value is determined.
    renderApplicationUI(isPartnerApp, patientType, commServerUrl);
}

void generateDynamicManifest();

// Just keep this unhandled, so when initialization fails we just get the unhandled rejection
// message that displays nice errors (at least for developers!)
void startApplication();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
